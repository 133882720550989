<template>
    <div class="profile_notes" v-if="modelValue">
        <div class="edit_modal">
            <div class="edit_section setting_wpr p-0">
                <div class="form_body">
                    <h2 class="title_header">Add Points<button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button></h2>
                    <Form @submit="handleRewardSend" v-slot="{ errors }" ref="profile-send-reward-form">
                        <div class="form_content">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Points Awarded</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.points }">
                                        <Field autocomplete="off" name="points" type="number" min="0" v-model="form.points" rules="required" />
                                    </div>
                                    <ErrorMessage name="points" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">You Just Earn {{ form.points }} Points For</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.reason }">
                                        <Field autocomplete="off" name="reason" type="text" v-model="form.reason" placeholder="Type Here..." rules="required" />
                                    </div>
                                    <ErrorMessage name="reason" class="text-danger" />
                                </div>
                            </div>
                            <label for="allow" class="switch_option capsule_btn">
                                <h5>Contact Notification</h5>
                                <input type="checkbox" :true-value="1" :false-value="0" id="allow" v-model="form.subscriber_notification" hidden>
                                <div><span></span></div>
                            </label>
                            <div v-if="form.subscriber_notification">
                                <h5 class="mt-3">Notification Method</h5>
                                <div class="edit_section bg-light mt-2 mb-5">
                                    <sending-method v-model="form.sending_method" />
                                </div>
                            </div>
                            <div class="action_wpr">
                                <button :disabled="rewardLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                                <button :disabled="rewardLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="rewardLoader"></i>{{ rewardLoader ? ' Adding' : 'Add'}}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        data () {
            return {
                form: {
                    contact_id: 0,
                    points: 0,
                    reason: '',
                    subscriber_notification: 1,
                    sending_method: 1,
                },
            }
        },

        props: {
            modelValue: Boolean,
            contact: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.form = {
                        contact_id: 0,
                        points: 0,
                        reason: '',
                        subscriber_notification: 1,
                        sending_method: 1,
                    };
                }
            },
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            rewardLoader: state => state.contactModule.rewardLoader,
        }),

        methods: {
            ...mapActions({
                sendReward: 'contactModule/sendReward',
                getContactReward: 'contactModule/getContactReward',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            handleRewardSend (form, { setFieldError }) {
                const vm = this;
                vm.form.contact_id    = vm.contact.id;
                vm.form.setFieldError = setFieldError;

                vm.sendReward(vm.form).then((result) => {
                    if (result) {
                        const params = {
                                            page: 1,
                                            per_page: 6,
                                            contact_id: vm.contact.id,
                                        };

                        vm.getContactReward(params);
                        vm.closeModal();
                    }
                });
            },
        }
    }
</script>
<style scoped>
.edit_modal .edit_section .form_body .form_content{
    padding-bottom: 0;
}
</style>
